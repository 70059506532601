<template>
  <div v-if="model && bloggerOptions">
    <h3 class="statistics__format--title">Заполните статистику аккаунта</h3>
    <div @click="showFillExample = true" class="textbluemobile">Как заполнять статистику</div>
    <div class="statistics__row flex-md-wrap flex-lg-nowrap">
      <div class="statistics__col">
        <div class="settings__info--wrap statistics__info--wrap">
          <h4 class="settings__title--small" style="margin-top: 0">
            <span style="font-weight: bold">Охваты</span> сторис
          </h4>
          <div class="statistics__title--desc">
            Заполните поля и загрузите подтверждающий скриншот с охватами сторис
          </div>
          <div class="w-100 set-fields">
            <coverage-field :validations="$v" v-model="model"></coverage-field>
            <div class="settings__info--text--down"></div>
          </div>
          <div
            @click="
              () => {
                noStories = !noStories;
                model.blogger_profile.coverage_min = 0;
                model.blogger_profile.coverage_max = 0;
                coverageScreen = null;
              }
            "
            class="settings__info--text--blue stata__notadvert"
            style="cursor: pointer"
          >
            {{ noStories ? 'П' : 'Не п' }}родаю рекламу в сторис и {{ noStories ? '' : 'не' }} хочу
            заполнять охваты сторис
          </div>
        </div>
        <div class="settings__info--wrap statistics__info--wrap">
          <h4 class="settings__title--small">
            <span style="font-weight: bold">Охваты</span> постов
            <span class="gray-text fs-12">({{ noStories ? '' : 'не ' }}обязательно)</span>
          </h4>
          <div class="statistics__title--desc">
            Заполните поля и загрузите подтверждающий скриншот с охватами постов
          </div>
          <div class="w-100 set-fields">
            <coverage-posts-field :validations="$v" v-model="model"></coverage-posts-field>
            <div class="settings__info--text--down settings__info--text--down--error"></div>
          </div>
        </div>
        <div class="settings__info--wrap statistics__info--wrap">
          <h4 class="settings__title--small">
            <span style="font-weight: bold">Процент</span> женщин
          </h4>
          <div class="statistics__title--desc">
            Заполните поле и загрузите подтверждающий скриншот с процентом женщин
          </div>
          <div class="w-100 set-fields">
            <women-percent-field :validations="$v" v-model="model"></women-percent-field>
            <div class="settings__info--text--down"></div>
          </div>
          <!-- <div class="settings__info--text--blue">Не продаю рекламу в сторис и не хочу заполнять охваты сторис</div> -->
        </div>
        <div class="settings__info--wrap statistics__info--wrap">
          <h4 class="settings__title--small">
            Преобладающий возраст в <span style="font-weight: bold">процентах</span>
          </h4>
          <div class="statistics__title--desc">
            Заполните поля и загрузите подтверждающий скриншот с возрастом в процентах
          </div>

          <div class="w-100 set-fields">
            <main-audience-field
              :validations="$v.model.blogger_profile.main_audience"
              v-model="model.blogger_profile.main_audience"
            ></main-audience-field>
            <div class="settings__info--text--down"></div>
          </div>

          <!-- <div class="settings__info--text--blue">Не продаю рекламу в сторис и не хочу заполнять охваты сторис</div> -->
        </div>
        <div class="settings__info--wrap statistics__info--wrap">
          <h4 class="settings__title--small">
            Преобладающая страна в <span style="font-weight: bold">процентах</span>
          </h4>
          <div class="statistics__title--desc">
            Заполните поля и загрузите подтверждающий скриншот со страной в процентах
          </div>
          <div class="w-100 set-fields">
            <main-country-field
              :validations="$v.model.blogger_profile.main_country"
              v-model="model.blogger_profile.main_country"
            ></main-country-field>
            <div class="settings__info--text--down settings__info--text--down--error"></div>
          </div>
          <!-- <div class="settings__info--text--blue">Не продаю рекламу в сторис и не хочу заполнять охваты сторис</div> -->
        </div>
        <div class="settings__info--wrap statistics__info--wrap" style="padding-bottom: 0">
          <h4 class="settings__title--small">
            Преобладающий город в <span style="font-weight: bold">процентах</span>
          </h4>
          <div class="statistics__title--desc">
            Заполните поля и загрузите подтверждающий скриншот со страной в процентах
          </div>
          <div class="w-100 set-fields">
            <main-city-field
              :validations="$v.model.blogger_profile.main_city"
              v-model="model.blogger_profile.main_city"
            ></main-city-field>
            <div class="settings__info--text--down"></div>
          </div>
          <!-- <div class="settings__info--text--blue">Не продаю рекламу в сторис и не хочу заполнять охваты сторис</div> -->
        </div>
      </div>
      <div
        v-if="!$route.query.hide_example"
        class="statistics__col stata__how statistics__how--none statistics__col--padding"
      >
        <div class="stata__ex">
          <h4 class="settings__title--small" style="margin-top: 0">
            Пример заполнения <span style="font-weight: bold"> охватов сторис</span>
          </h4>
          <img
            src="../../../assets/img/content/stataexample.png"
            alt="stataexample"
            class="statistics__example--pic"
          />
          <div class="statistics__example--text">
            Модератор не примет просмотры
            <span style="color: #ff0000">вместо </span>
            <span style="color: #ff0000; font-weight: 700">охватов.</span>
          </div>
          <div class="statistics__example--text">
            В статистике находите минимальное и максимальное число охватов сторис за последние 14
            дней. Заполняете в соответствующие поля и загружаете скриншот.
          </div>
          <div class="statistics__example--text">Пример скриншота с охватами сторис:</div>
          <img
            src="../../../assets/img/content/statastories.png"
            alt="stories"
            class="statistics__example--text"
          />
        </div>
        <div class="stata__ex">
          <h4 class="settings__title--small">
            Пример заполнения <span style="font-weight: bold"> охватов постов</span>
          </h4>
          <img
            src="../../../assets/img/content/exposts.png"
            alt="stataexample"
            class="statistics__example--pic"
          />
          <div class="statistics__example--text">
            Модератор не примет просмотры
            <span style="color: #ff0000">вместо </span>
            <span style="color: #ff0000; font-weight: 700">охватов.</span>
          </div>
          <div class="statistics__example--text">
            В статистике находите минимальное и максимальное число охватов постов за последние 30
            дней. Заполняете в соответствующие поля и загружаете скриншот.
          </div>
          <div class="statistics__example--text">Пример скриншота с охватами сторис:</div>
          <img
            src="../../../assets/img/content/screenposts.png"
            alt="stories"
            class="statistics__example--text"
          />
        </div>
        <div class="stata__ex">
          <h4 class="settings__title--small" style="margin-top: 0">
            Пример заполнения <span style="font-weight: bold"> процента женщин</span>
          </h4>
          <img
            src="../../../assets/img/content/exwoman.png"
            alt="stataexample"
            class="statistics__example--pic"
          />
          <div class="statistics__example--text">
            Модератор не примет просмотры
            <span style="color: #ff0000">вместо </span>
            <span style="color: #ff0000; font-weight: 700">охватов.</span>
          </div>
          <div class="statistics__example--text">
            В статистике вашего аккаунта находите процент женщин. Заполняете в соответствующее поле
            и загружаете скриншот.
          </div>
          <div class="statistics__example--text">Пример скриншота с охватами сторис:</div>
          <img
            src="../../../assets/img/content/exstata.png"
            alt="stories"
            class="statistics__example--text"
          />
        </div>
      </div>
    </div>
    <span v-if="error" class="err-txt_stat-form--mobile">{{ error }}</span>
    <div class="statistics__buttons">
      <b-button
        :disabled="loading"
        variant="outline-default"
        class="btn--stat__controls"
        @click="() => $emit('back')"
        >Назад</b-button
      >
      <span v-if="error" class="err-txt_stat-form">{{ error }}</span>
      <b-button variant="warning" class="btn--stat__controls" :disabled="loading" @click="submit">
        Далее
      </b-button>
    </div>
    <FillExample @close="showFillExample = false" :open="showFillExample" />
  </div>
</template>

<script>
import CitySelect from '@main/components/reusable/elements/selects/CitySelect.vue';
/* eslint-disable func-names, object-shorthand */

import FillExample from '@main/components/reusable/modals/FillExample.vue';
import Multiselect from 'vue-multiselect';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import StatisticFormValidationMixin from '@main/mixins/statistic/StatisticFormValidationMixin.vue';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import SingleFileInput from '../../forms/SingleFileInput.vue';
import bloggers from '../../../api/bloggers';
import auth from '../../../api/users';
import CoverageField from '../fields/CoverageField.vue';
import CoveragePostsField from '../fields/CoveragePostsField.vue';
import WomenPercentField from '../fields/WomenPercentField.vue';
import MainAudienceField from '../fields/MainAudienceField.vue';
import MainCountryField from '../fields/MainCountryField.vue';
import MainCityField from '../fields/MainCityField.vue';

export default {
  components: {
    FillExample,
    CoverageField,
    CoveragePostsField,
    WomenPercentField,
    MainAudienceField,
    MainCountryField,
    MainCityField,
  },
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    model: Object,
  },
  mixins: [StatisticFormValidationMixin],
  data: () => ({
    error: '',
    loading: false,
    fileLoading: false,
    ageGroup: 0,
    showFillExample: false,
    noStories: false,
  }),
  computed: {
    ...mapState(['currentUser', 'bloggerOptions', 'tags', 'countries', 'cities']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    currentAudience() {
      if (!this.model.blogger_profile) {
        return;
      }
      return this.options.audiences.child.children.age_group.choices.filter(
        (val) => val.value === this.model.blogger_profile.main_audience.age_group
      )[0];
    },
  },
  methods: {
    ...mapActions(['loadUser']),
    ...mapMutations('notifications', ['showMessage']),
    async submit() {
      this.loading = true;
      this.$v.$touch();

      if (this.$v.$anyError) {
        this.error = 'Заполните поля отмеченные красным и загрузите скриншоты';
        this.loading = false;
        return;
      }
      this.$parent.error = '';
      try {
        await auth.updateMe(this.model);
        this.$emit('next');
      } catch (e) {
        this.showMessage({
          title:
            'Произошла ошибка при сохранении профиля. Попробуйте позднее или свяжитесь с администрацией!',
          icon: 2,
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.coverage-blocks {
  flex-wrap: inherit !important;
}
</style>
