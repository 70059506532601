<template>
  <div v-if="model">
    <h3 class="settings__title statistics__format--title">Темы аккаунта</h3>
    <div class="themes">
      <div class="themes__row">
        <div class="themes__col themes__col--padd coll-transform">
          <form class="themes__form mr-md-4">
            <div v-if="!currentUser.profile.email" class="themes__block">
              <h3 class="themes__title">Ваш e-mail</h3>
              <b-input
                :class="{ error: $v.model.blogger_profile.main_email.$anyError }"
                v-model.trim="model.blogger_profile.main_email"
                type="text"
                class="settings__input themes__input ph-36"
              />
              <div class="themes__info">
                После подтверждения на него будут приходить напоминания о необходимости обновить
                статистику карточки
              </div>
            </div>
            <div v-else class="themes__block">
              <h3 class="themes__title">Ваш e-mail</h3>
              <b-input
                :value="currentUser.profile.email"
                type="text"
                disabled
                class="settings__input themes__input ph-36"
              />
            </div>
            <div class="themes__block">
              <div class="setting__themes--block filters__row--themes">
                <h3 class="settings__title--small" style="white-space: nowrap">
                  Укажите 1-3 темы вашего аккаунта
                </h3>
                <Multiselect
                  style="max-width: none !important"
                  :class="{ error: $v.model.blogger_profile.tags.$anyError }"
                  v-model="model.blogger_profile.tags"
                  placeholder="Выберите из списка"
                  :multiple="true"
                  :max="3"
                  :show-labels="false"
                  :options="tags"
                  track-by="id"
                  label="name"
                />
              </div>
            </div>
            <div class="themes__block">
              <div class="setting__themes--block">
                <h3 class="settings__title--small">Какие темы не рекламируете</h3>
                <textarea
                  :class="{ error: $v.model.blogger_profile.excluded_tags.$anyError }"
                  v-model="model.blogger_profile.excluded_tags"
                  name="themes-textarea"
                  style="font-size: 14px; width: 100%; height: 120px"
                  placeholder="Например: Cетевой бизнес, астрология, гадания, казино."
                  class="settings__themes--textarea settings__themes--textarea--2"
                ></textarea>
              </div>
            </div>
            <div class="themes__block">
              <h3 class="themes__title">Город проживания</h3>
              <b-input
                :class="{ error: $v.model.blogger_profile.current_city.$anyError }"
                v-model="model.blogger_profile.current_city"
                type="text"
                class="settings__input themes__input ph-36"
              />
            </div>
            <div class="themes__block">
              <h3 class="themes__title">Юридический статус</h3>
              <div class="settings__common--checkboxes">
                <div
                  v-for="(block, idx) in legalStatusList"
                  :key="`${idx}-blcklglSttsLst`"
                  class="settings__common--checkboxes--block"
                >
                  <label
                    v-for="(i, idx) in block"
                    :key="`${idx}-blckLglSttsaWChck`"
                    class="legal-status"
                  >
                    <input
                      type="radio"
                      :value="i.value"
                      v-model="model.blogger_profile.legal_status"
                      class="legal-status--radio status__check"
                    />
                    <span
                      :class="{ error: $v.model.blogger_profile.legal_status.$anyError }"
                      class="legal-status__block"
                    ></span>
                    <span class="legal-status__text">{{ i.display_name }}</span>
                  </label>
                </div>
              </div>
              <template v-if="legalStatusMapping[model.blogger_profile.legal_status]">
                <div
                  class="settings__common--opac"
                  style="margin-top: 14px; margin-bottom: 8px"
                  title="Недоступен для физ. лиц"
                >
                  {{ legalStatusMapping[model.blogger_profile.legal_status].name }}
                </div>
                <b-input
                  :class="{
                    error:
                      $v.model.blogger_profile[
                        legalStatusMapping[model.blogger_profile.legal_status].model
                      ].$anyError,
                  }"
                  :disabled="model.blogger_profile.legal_status === 1"
                  type="text"
                  name="cipher"
                  onkeypress="return event.charCode > 47 && event.charCode < 58;"
                  class="settings__input ph-36"
                  :maxlength="legalStatusMapping[model.blogger_profile.legal_status].len"
                  :value="
                    model.blogger_profile[
                      legalStatusMapping[model.blogger_profile.legal_status].model
                    ]
                  "
                  @change="
                    (val) => {
                      model.blogger_profile[
                        legalStatusMapping[model.blogger_profile.legal_status].model
                      ] = val;
                    }
                  "
                  id="cipher"
                />
                <div class="settings__common--opac" style="margin-top: 8px">
                  {{ legalStatusMapping[model.blogger_profile.legal_status].name }}
                  необходим для подтверждения вашего юридического статуса и не будет указан на сайте
                </div>
              </template>
            </div>
          </form>
        </div>
        <div class="themes__col">
          <form class="themes__form">
            <div class="themes__form--first">
              <div class="themes__block themes__block--margin">
                <input
                  v-model="allCheckboxes"
                  class="setting__common--check"
                  type="checkbox"
                  id="checkall"
                  name="checkall"
                />
                <label class="setting__common--label label__fill" for="checkall">
                  <span class="statistics__ml statistics__question">Выбрать все</span>
                </label>
              </div>
              <!--div
                v-if="model.blogger_profile.coverage_min > 5000"
                class="themes__block themes__block--margin"
              >
                <input
                  v-model="model.blogger_profile.brand"
                  class="setting__common--check"
                  type="checkbox"
                  id="coop"
                  name="coop"
                />
                <label class="setting__common--label label__fill" for="coop">
                  <span class="statistics__question">
                    Хочу вступить в рекламное агентство Easyprbot, чтобы получать дополнительные
                    предложения от рекламодателей.
                  </span>
                </label>
              </!--div-->
              <!--div
                v-if="model.blogger_profile.coverage_min > 1000"
                class="themes__block themes__block--margin"
              >
                <input
                  v-model="model.blogger_profile.infoproduct"
                  class="setting__common--check checkbox__open"
                  type="checkbox"
                  id="infoprod"
                  name="infoprod"
                />
                <label class="setting__common--label label__fill label__fill--info" for="infoprod">
                  <span class="statistics__question"
                    >Хочу создать свой инфопродукт или запустить уже существующий. Хочу записаться
                    на консультацию у продюсерского центра.</span
                  >
                </label>
              </!--div-->
            </div>
            <div class="themes__form--sec">
              <div class="themes__block themes__block--margin">
                <input
                  v-model="policyAgree"
                  @change="() => $v.policyAgree.$touch()"
                  class="setting__common--check"
                  type="checkbox"
                  id="agree"
                  name="agree"
                />
                <label
                  :class="{ error: $v.policyAgree.$anyError }"
                  class="statistics__question setting__common--label label--pd label__fill"
                  style="display: inline-block; border: none !important"
                  for="agree"
                >
                  <span class="statistics__ml">
                    Я соглашаюсь с
                    <a href="/privacy/" target="_blank" style="color: #3897f0"
                      >Политикой конфиденциальности</a
                    >
                    и
                    <a href="/policy/" target="_blank" style="color: #3897f0"
                      >Пользовательским соглашением</a
                    >
                  </span>
                </label>
              </div>
              <div class="themes__block themes__block--margin">
                <input
                  v-model="model.blogger_profile.send_ads"
                  class="setting__common--check"
                  type="checkbox"
                  id="notif"
                  name="notif"
                />
                <label class="statistics__question setting__common--label label__fill" for="notif">
                  <span class="statistics__ml">
                    Я соглашаюсь на получение рекламных материалов с сайта Easyprbot.com
                  </span>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <span v-if="error" class="err-txt_stat-form--mobile">{{ error }}</span>
    <div class="statistics__buttons">
      <b-button class="btn--stat__controls" variant="outline-default" @click="() => $emit('back')"
        >Назад</b-button
      >
      <span v-if="error" class="err-txt_stat-form">{{ error }}</span>
      <b-button variant="warning" class="btn--stat__controls" @click="submit"> Далее </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable func-names, object-shorthand */

import Multiselect from 'vue-multiselect';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import { mapActions, mapState, mapGetters } from 'vuex';
import { required, minLength, between, email, requiredIf } from 'vuelidate/lib/validators';
import auth from '@main/api/users';

export default {
  components: {
    Multiselect,
  },
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    model: Object,
  },
  data: () => ({
    error: '',
    policyAgree: null,
    legalStatusMapping: {
      2: {
        name: 'ИНН',
        model: 'inn',
        len: '10',
      },
      3: {
        name: 'ОГРНИП',
        model: 'ogrnip',
        len: '15',
      },
      4: {
        name: 'ОГРН',
        model: 'ogrn',
        len: '13',
      },
    },
  }),
  validations: {
    policyAgree: {
      required,
      async noFalse(val) {
        return val === true;
      },
    },
    model: {
      blogger_profile: {
        tags: { required },
        main_email: {
          async req(val) {
            if (this.currentUser.profile.email) {
              return true;
            }
            return required(val);
          },
          email,
        },
        excluded_tags: { required },
        legal_status: { required },
        inn: {
          async req() {
            return this.model.blogger_profile.legal_status === 1 || this.model.blogger_profile.inn;
          },
        },
        ogrn: {
          async req() {
            return this.model.blogger_profile.legal_status === 1 || this.model.blogger_profile.ogrn;
          },
        },
        ogrnip: {
          async req() {
            return (
              this.model.blogger_profile.legal_status === 1 || this.model.blogger_profile.ogrnip
            );
          },
        },
        current_city: { required },
      },
    },
  },
  computed: {
    ...mapState(['tags', 'currentUser', 'bloggerOptions']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    allCheckboxes: {
      get() {
        /* eslint-disable camelcase */
        const { blogger_profile } = this.model;
        if (blogger_profile.coverage_min > 5000) {
          if (!blogger_profile.brand) {
            return;
          }
        }
        if (blogger_profile.coverage_min > 1000) {
          if (!blogger_profile.infoproduct) {
            return;
          }
        }
        return this.policyAgree && blogger_profile.send_ads;
      },
      set(val) {
        /* eslint-disable camelcase */
        const { blogger_profile } = this.model;
        if (val) {
          if (blogger_profile.coverage_min > 5000) {
            blogger_profile.brand = true;
          }
          if (blogger_profile.coverage_min > 1000) {
            blogger_profile.infoproduct = true;
          }
          this.policyAgree = true;
          blogger_profile.send_ads = true;
        } else {
          blogger_profile.infoproduct = false;
          blogger_profile.brand = false;
          this.policyAgree = false;
          blogger_profile.send_ads = false;
        }
      },
    },
    legalStatusList() {
      const chunkArray = (myArray, chunkSize) => {
        let index = 0;
        const arrayLength = myArray.length;
        const tempArray = [];
        let myChunk;

        for (index = 0; index < arrayLength; index += chunkSize) {
          myChunk = myArray.slice(index, index + chunkSize);
          tempArray.push(myChunk);
        }

        return tempArray;
      };
      return chunkArray(this.options.legal_status.choices, 2);
    },
  },
  methods: {
    ...mapActions(['loadUser']),
    async performRedirects() {
      await this.$router.push({ name: 'home' });
      if (this.model.blogger_profile.infoproduct) {
        await this.$router.$setNextPage(this.$router, this.$route, 'routename:create-infoproduct', {
          prepend: true,
        });
      }
      if (this.model.blogger_profile.brand) {
        await this.$router.$setNextPage(this.$router, this.$route, 'routename:pr-agency-apply', {
          prepend: true,
        });
      }
      const redirect = await this.$router.$getNextPage(this.$router, this.$route);
      if (!redirect) {
        await this.$router.push('/mystat');
      }
    },
    submit() {
      this.$v.$touch();
      this.$v.policyAgree.$touch();
      if (this.$v.$anyError || this.$v.policyAgree.$anyError || !this.policyAgree) {
        this.error = 'Заполните поля отмеченные красным и нажмите далее';
        return;
      }
      try {
        auth.updateMe(this.model).then(() => {
          this.loadUser().then((res) => {
            this.$store.commit('notifications/showMessage', {
              title: 'Карточка отправлена на модерацию! Обычно она занимает около 1-2 дней.',
              icon: 1,
              id: 5411451235256132,
            });

            if (!this.currentUser.profile.email) {
              auth
                .sendEmail(this.currentUser.blogger_profile.main_email, '/gathering/main/')
                .then(() => {
                  this.$store.commit('notifications/showMessage', {
                    title: `Сообщение с подтверждением e-mail отправлено на ${this.currentUser.blogger_profile.main_email}`,
                    icon: 1,
                    id: 54156132,
                  });
                })
                .catch((e) => {
                  console.log(e);
                });
            }
            this.performRedirects().then(() => {
              console.log('success');
            });
          });
        });
      } catch (e) {
        console.log(e);
        this.$store.dispatch('notifications/setWindow', {
          text: 'Ошибка при сохранении карточки в базу! Попробуйте позже или обратитесь в техническую поддержку - help@easyprbot.com',
          iconPath: alertGreyIcon,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.coll-transform {
  width: 50%;
}
@media (max-width: 768px) {
  .coll-transform {
    width: 100% !important;
  }
}
</style>
